import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class Environment {

  mode = 'prod';

  APP_VERSION = '0.0.0-alpha';
  APP_NAME = 'Votever';

  SERVER_URL = '';
  CLIENT_ID = 2;
  CLIENT_SECRET = '';
  FIREBASE_CONFIG = {
    apiKey: "AIzaSyBR91Z7sIC8XgjD6tMGQCJBte-2OqsTqX0",
    authDomain: "votever-83329.firebaseapp.com",
    projectId: "votever-83329",
    storageBucket: "votever-83329.appspot.com",
    messagingSenderId: "694407394107",
    appId: "1:694407394107:web:eece35ab6fc29d8d797d1e",
    measurementId: "G-MYFTZR9HYJ"
  }

  constructor() {

    const browserWindow = window || {};
    const browserWindowEnv = browserWindow['__env'] || {};

    switch (this.mode) {
      case 'dev':
        this.SERVER_URL = (browserWindowEnv.SERVER_URL == '${SERVER_URL}' ? 'http://localhost:8000/' : browserWindowEnv.SERVER_URL);
        this.CLIENT_ID = (browserWindowEnv.CLIENT_ID == '${CLIENT_ID}' ? 2 : browserWindowEnv.CLIENT_ID);
        this.CLIENT_SECRET = (browserWindowEnv.CLIENT_SECRET == '${CLIENT_SECRET}' ? 'f1xMOFPfozjTl59VaTPMneZy5CseVZcBtptipWOb' : browserWindowEnv.CLIENT_SECRET);
        break;
      case 'prod':
        // this.SERVER_URL = (browserWindowEnv.SERVER_URL == '${SERVER_URL}' ? 'https://api.votever.id/' : browserWindowEnv.SERVER_URL);
        this.SERVER_URL = (browserWindowEnv.SERVER_URL == '${SERVER_URL}' ? 'https://api.votever.dicicip.com/' : browserWindowEnv.SERVER_URL);
        this.CLIENT_ID = (browserWindowEnv.CLIENT_ID == '${CLIENT_ID}' ? 2 : browserWindowEnv.CLIENT_ID);
        // this.CLIENT_SECRET = (browserWindowEnv.CLIENT_SECRET == '${CLIENT_SECRET}' ? 'DGJfvMa8A1M8SSCLNOyHJrfkgKDAJ3EfHIyMaK0d' : browserWindowEnv.CLIENT_SECRET);
        this.CLIENT_SECRET = (browserWindowEnv.CLIENT_SECRET == '${CLIENT_SECRET}' ? 'o4IteEBeboUDmBSs7i4WfKx7KHN5QjhRplxO4qhj' : browserWindowEnv.CLIENT_SECRET);
        break;
    }
  }
}

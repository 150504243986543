import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class EventBusService {
    private subject = new Subject<any>();

    sendEvent(eventName, payload = null) {
        this.subject.next({
            name: eventName,
            payload: payload
        });
    }

    listenEvents(): Observable<any> {
        return this.subject.asObservable();
    }
}

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class EventsNameService {

    // Auth
    ON_LOG_IN = 'ON_LOG_IN';
    ON_LOG_OUT = 'ON_LOG_OUT';
    ON_REGISTER = 'ON_REGISTER';
    ON_FORGET_PASSWORD = 'ON_FORGET_PASSWORD';

    // Profile
    ON_PROFILE_CHANGE = 'ON_PROFILE_CHANGE';

    ON_DASHBOARD_FILTERS_CHANGE = 'ON_DASHBOARD_FILTERS_CHANGE';

    event_vote_status_change = 'event_vote_status_change';

    event_on_search_votes = 'event_on_search_votes';
}

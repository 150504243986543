import { Environment } from './utils/environment';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { AppComponent } from './app.component';
// import { AutoFocus } from './modules/landing/login-with-token/autofocus.directive';

import { registerLocaleData } from '@angular/common';
import localeId from '@angular/common/locales/id';

registerLocaleData(localeId, 'id-ID');

const env: Environment = new Environment();

const routes: Routes = [

  { path: '', loadChildren: './modules/landing/landing.module#LandingModule' },
  { path: 'admin', loadChildren: './modules/admin/admin.module#AdminModule' },

];

@NgModule({
  declarations: [
    AppComponent,
    // AutoFocus,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    NgxSpinnerModule,


    AngularFireModule.initializeApp(env.FIREBASE_CONFIG),
    // AngularFireModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
  ],
  providers: [
    // { provide: AUTH_SETTINGS, useValue: { appVerificationDisabledForTesting: true } },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import Swal from 'sweetalert2';
import { NativeAuthService } from './services/auth.service';
import { Subscription } from 'rxjs/Subscription';
import { EventsNameService } from './services/events-name.service';
import { EventBusService } from './services/event-bus.service';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd, RouterOutlet } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

    routeSubscribe: any;
    eventBusSubscription: Subscription;

    constructor(
        private router: Router,
        private spinner: NgxSpinnerService,
        private eventBusService: EventBusService,
        private eventsNameService: EventsNameService,
        private authService: NativeAuthService,
    ) { }

    ngOnInit() {

        this.routeSubscribe = this.router.events.subscribe((event) => {

            // On Load Lazy Modules
            if (event instanceof RouteConfigLoadStart) {
                console.log('ROUTER-CHANGE-RouteConfigLoadStart', event);
                setTimeout(() => {
                    this.spinner.show();
                }, 300);
            }

            // On Lazy Modules Loaded
            if (event instanceof RouteConfigLoadEnd) {
                console.log('ROUTER-CHANGE-RouteConfigLoadEnd', event);
                setTimeout(() => {
                    this.spinner.hide();
                }, 300);
            }


        });

        this.eventBusSubscription = this.eventBusService.listenEvents().subscribe(
            event => {
                console.log('event-AppComponent', event);

                switch (event.name) {
                    case this.eventsNameService.ON_LOG_IN:
                        break;

                    case this.eventsNameService.ON_LOG_OUT:
                        this.spinner.show();
                        this.authService.logout().then(
                            response => {
                                Swal.fire(
                                    'Yay !',
                                    'Successfully Logged out',
                                    'success'
                                );
                            },
                            error => {
                            }
                        );

                        localStorage.clear();

                        this.router.navigate(['/login']);
                        this.spinner.hide();
                        break;

                    case this.eventsNameService.event_on_search_votes:
                        this.router.navigate(
                            ['/admin/home/vote'],
                            {
                                queryParams: {
                                    keyword: event.payload.keyword
                                }
                            }
                        );
                        break;
                }
            }
        );
    }

    prepareRoute(outlet: RouterOutlet) {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
    }

    ngOnDestroy(): void {
        console.log('APP-ngOnDestroy()');
        this.routeSubscribe.unsubscribe();
    }
}

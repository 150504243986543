import { Subscription } from 'rxjs/Subscription';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { httpClient, httpAuthClient } from './../network/http-client';
import { Injectable } from '@angular/core';

import { Environment } from '../utils/environment';
import { AxiosPromise } from 'axios';
// import { AngularFireAuth } from '@angular/fire/auth';
// import { auth } from 'firebase';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({ providedIn: 'root' })
export class NativeAuthService {

  static USER_FOUND_SOCIAL_NOT_FOUND = 'USER_FOUND_SOCIAL_NOT_FOUND';
  static USER_FOUND_SOCIAL_FOUND = 'USER_FOUND_SOCIAL_FOUND';
  static USER_NOT_FOUND = 'USER_NOT_FOUND';

  // firebaseToken = '';
  tokenSubsciber: Subscription;

  userInfo: any;

  constructor(
    private env: Environment,
    // private fireAuth: AngularFireAuth,
    private router: Router,
    private spinner: NgxSpinnerService,
  ) {
  }

  loginWithSocialCredentials(data): AxiosPromise<any> {
    return httpClient.post(
      'api/v1/login/social/secret',
      data
    );
  }

  loginSocial(data): AxiosPromise<any> {
    return httpClient.post(
      'api/v1/login/social',
      data
    );
  }

  checkAuth(): AxiosPromise<any> {
    return httpAuthClient.get('api/v1/me/auth');
  }

  login(user): AxiosPromise<any> {
    return httpClient.post('oauth/token', {
      grant_type: 'password',
      client_id: this.env.CLIENT_ID,
      client_secret: this.env.CLIENT_SECRET,
      username: user.email,
      password: user.password,
      scope: ''
    });
  }

  loginWithToken(token): AxiosPromise<any> {
    return httpClient.post('api/v1/login/tokens', {
      token: token
    });
  }

  loginWithSocial(user): AxiosPromise<any> {
    return httpClient.post(`api/v1/login-with-social`, user);
  }

  refreshToken(): AxiosPromise<any> {
    return httpAuthClient.post('oauth/token', {
      grant_type: 'refresh_token',
      refresh_token: `${JSON.parse(localStorage.getItem('credentials')).refresh_token}`,
      client_id: this.env.CLIENT_ID,
      client_secret: this.env.CLIENT_SECRET,
      scope: ''
    });
  }

  logout(): AxiosPromise<any> {
    return httpAuthClient.post(`api/v1/me/logout`, {});
  }

  register(user): AxiosPromise<any> {
    return httpClient.post(`api/v1/register`, user);
  }

  registerWithGoogle(): Promise<any> {
    return null;
    // return this.fireAuth.signInWithPopup(new auth.GoogleAuthProvider());
  }

  registerWithSocial(user): AxiosPromise<any> {
    return httpClient.post(`api/v1/register-with-social`, user);
  }

  verifySocialAndJoinVote() {

    if (this.tokenSubsciber) {
      this.tokenSubsciber.unsubscribe();
    }

    console.log('verifySocial()');
    this.spinner.show();
    // this.tokenSubsciber = this.fireAuth.idToken.subscribe(
    //   token => {
    //     console.log('token', token);
    //     this.tokenSubsciber.unsubscribe();

    //     if (token) {

    //       httpClient.post(`api/v1/verify-firebase-token`, { token }).then(
    //         response => {
    //           this.spinner.hide();
    //           console.log('response.data', response.data);

    //           const userData = response.data.data;
    //           console.log('verifyFirebaseAccessToken-response', userData);

    //           const status = response.data.status;
    //           console.log('RESPONSE_STATUS', status);

    //           switch (status) {
    //             case NativeAuthService.USER_NOT_FOUND:
    //               // this.router.navigate(['/register-social'], {
    //               //   queryParams: userData
    //               // });
    //               break;
    //             case NativeAuthService.USER_FOUND_SOCIAL_NOT_FOUND:

    //               // Swal.fire({
    //               //   title: 'Email is already taken',
    //               //   text: `login and link your ${this.env.APP_NAME} account with this social media account before using it as authentication method`,
    //               //   icon: 'warning'
    //               // });

    //               break;
    //             case NativeAuthService.USER_FOUND_SOCIAL_FOUND:

    //               this.loginWithSocial({
    //                 'user_social_id': userData.uid,
    //                 'firebase_token': token,
    //               }).then(
    //                 loginWithSocialResponse => {
    //                   this.spinner.hide();
    //                   console.log('loginWithSocialResponse', loginWithSocialResponse.data);

    //                   // const data = loginWithSocialResponse.data.data;
    //                   // localStorage.setItem('credentials', JSON.stringify(data));

    //                   // this.userInfo = JSON.parse(localStorage.getItem('userInfo'));

    //                   // this.router.navigate(['/admin/me/vote']);

    //                 },
    //                 loginWithSocialError => {
    //                   this.spinner.hide();
    //                   console.log('loginWithSocialResponse', loginWithSocialError);

    //                   const data = loginWithSocialError.response.data;

    //                   Swal.fire(
    //                     'Oops',
    //                     data.message,
    //                     'error'
    //                   );

    //                 }
    //               );

    //               break;
    //           }


    //         },
    //         error => {
    //           this.spinner.hide();
    //           console.log('error', error);

    //           const data = error.response.data;

    //           Swal.fire(
    //             'Oops',
    //             data.message,
    //             'error'
    //           );

    //         }
    //       );

    //     } else {
    //       this.spinner.hide();

    //       Swal.fire(
    //         'Oops',
    //         'Failed to login',
    //         'error'
    //       );

    //     }

    //   }
    // );
  }

  verifySocial(provider) {

    if (this.tokenSubsciber) {
      this.tokenSubsciber.unsubscribe();
    }

    console.log('verifySocial()');
    this.spinner.show();
    // this.tokenSubsciber = this.fireAuth.idToken.subscribe(
    //   token => {
    //     console.log('token', token);
    //     this.tokenSubsciber.unsubscribe();

    //     if (token) {

    //       httpClient.post(`api/v1/verify-firebase-token`, { token }).then(
    //         response => {
    //           this.spinner.hide();
    //           console.log('response.data', response.data);

    //           const userData = response.data.data;
    //           userData.provider = provider;
    //           console.log('verifyFirebaseAccessToken-response', userData);

    //           const status = response.data.status;
    //           console.log('RESPONSE_STATUS', status);

    //           switch (status) {
    //             case NativeAuthService.USER_NOT_FOUND:
    //               this.router.navigate(['/register-social'], {
    //                 queryParams: userData
    //               });
    //               break;
    //             case NativeAuthService.USER_FOUND_SOCIAL_NOT_FOUND:

    //               Swal.fire({
    //                 title: 'Email is already taken',
    //                 text: `login and link your ${this.env.APP_NAME} account with this social media account before using it as authentication method`,
    //                 icon: 'warning'
    //               });

    //               break;
    //             case NativeAuthService.USER_FOUND_SOCIAL_FOUND:

    //               this.loginWithSocial({
    //                 'user_social_id': userData.uid,
    //                 'firebase_token': token,
    //               }).then(
    //                 loginWithSocialResponse => {
    //                   this.spinner.hide();
    //                   console.log('loginWithSocialResponse', loginWithSocialResponse.data);

    //                   const data = loginWithSocialResponse.data.data;
    //                   localStorage.setItem('credentials', JSON.stringify(data));

    //                   this.router.navigate(['/admin/me/vote']);

    //                 },
    //                 loginWithSocialError => {
    //                   this.spinner.hide();
    //                   console.log('loginWithSocialResponse', loginWithSocialError);

    //                   const data = loginWithSocialError.response.data;

    //                   Swal.fire(
    //                     'Oops',
    //                     data.message,
    //                     'error'
    //                   );

    //                 }
    //               );

    //               break;
    //           }


    //         },
    //         error => {
    //           this.spinner.hide();
    //           console.log('error', error);

    //           const data = error.response.data;

    //           Swal.fire(
    //             'Oops',
    //             data.message,
    //             'error'
    //           );

    //         }
    //       );

    //     } else {
    //       this.spinner.hide();

    //       Swal.fire(
    //         'Oops',
    //         'Failed to login',
    //         'error'
    //       );

    //     }

    //   }
    // );
  }

  loginAndJoinVote(data): AxiosPromise<any> {
    return httpClient.post(`api/v1/login-and-join-vote`, data);
  }

  registerAndJoinVote(data): AxiosPromise<any> {
    return httpClient.post(`api/v1/register-and-join-vote`, data);
  }

  // Social Login
  loginWithGoogle(): Promise<any> {
    return null;
    // return this.fireAuth.signInWithPopup(new auth.GoogleAuthProvider());
  }

  isEmailTaken(email): AxiosPromise<any> {
    return httpClient.get(`api/v1/is-email-taken/${email}`);
  }

}
